/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Table,
  ButtonGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
  InputGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Form,
  FormText,
  Spinner
} from "reactstrap";
import Firebase from "firebase";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
import moment from "moment";

import { Grid } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
const gf = new GiphyFetch('d6r2Pnp7hZLTR6DpOxLDr4fS1H15BBgC')

// configure your fetch: fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)

// declare var Instamojo;

const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
  appId: "1:587064425563:web:99aac8f9a9a8b7cae9137e",
  measurementId: "G-5VFLZ6SK9T"
}
Firebase.initializeApp(firebaseConfig);

function ExtendedTables() {

//  const currentURL = window.location.href

 let pathname;

 let storeName = "";
 let tableNumber = "";
 let urlOrderID = "";


pathname = window.location.pathname

if(pathname == '/' || !pathname){
window?.location?.replace('https://onroad.app');
}else{
  let params = (new URL(document.location)).searchParams;
  tableNumber = params.get("table");
  urlOrderID = params.get("orderid");
  storeName = pathname.substring(1).split("-").join(" ").toUpperCase();
}
 
  
  const [list, setList] = React.useState();
  const [baseList, setBaseList] = React.useState();

  const [cart, setCart] = React.useState();
  const [input, setInput] = React.useState();
  const [cartView, setCartView] = React.useState(false);
  const [storeUiniqId, setStoreUiniqId] = React.useState();

  const [openedCollapses, setOpenCollapses] = React.useState(["collapseOne"]);
  const [hTabs, sethTabs] = React.useState("ht1");
  const [vTabs, setvTabs] = React.useState("vt1");
  const [vTabsIcons, setvTabsIcons] = React.useState("vti1");
  const [pageSubcategories, setpageSubcategories] = React.useState("ps1");
  const [modalClassic, setModalClassic] = React.useState(false);
  const [address, setAddress] = React.useState();
  const [addressLatLng, setAddressLatLng] = React.useState();
  const [addressLocation, setAddressLocation] = React.useState();
  const [phone, setPhone] = React.useState();
  const [name, setName] = React.useState();
  const [landMark, setLandMark] = React.useState();
  const [orderId, setOrderId] = React.useState();
  const [isError, setIsError] = React.useState();
  const [people, setPeople] = React.useState();
  const [paymentMethod, setPaymentMethod] = React.useState("cashondelivery");
  const [currentOffer, setCurrentOffer] = React.useState();
  const [distanceMatrixData, setDistanceMatrixData] = React.useState();
  const [storeLocation, setStoreLocation] = React.useState();
  const [showSuccessMessage, setShowSuccessMessage] = React.useState();
  const [previousOrders, setPreviousOrders] = React.useState();
  const [storeConfig, setStoreConfig] = React.useState();
  const [menuModal, setMenuModal] = React.useState();
  const [orderPlacedTime, setOrderPlacedTime] = React.useState();
  const [previousOrdersModal, setPreviousOrdersModal] = React.useState();

  const [storeBranches, setStoreBranches] = React.useState();
  const [branchSelectionModal, setBranchSelectionModal] = React.useState(false);  
  const [selectedBranch, setSelectedBranch] = React.useState();  
  const [giphyEmbedURL, setGiphyEmbedURL] = React.useState();  

  const google = window.google;
  const service = new google.maps.DistanceMatrixService();

  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  const togglePrevisouOrderModal = () => {
    setPreviousOrdersModal(!previousOrdersModal);
  };
  

  const toggleMenuModal = () => {
    setMenuModal(!menuModal);
  };
  const { width } = useWindowSize();

  React.useEffect(() => {
    if(!pathname){
      return
    }
    const onChildAdd = Firebase.database()
      .ref(`/pathtoid/${pathname.substring(1)}/`)
      .once('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setStoreUiniqId(data)
        }
      });
    
    return () => Firebase.database().ref(`/pathtoid/${pathname.substring(1)}/`).off('value', onChildAdd);
  }, [pathname])

  React.useEffect(() => {
    const a = window.localStorage.getItem("onroadcart")
    if(a){
      const t = JSON.parse(a)
      if(t.length > 0){
        setPreviousOrders(t)
      }
    }
  }, [])


  // http://localhost:3000/easy-take-store?orderid=-N-2AHsESjnn2WUKnCxV
  React.useEffect(() => {
      Firebase.database()
      .ref(`/tableorders/${storeUiniqId}/${urlOrderID}`)
      .once('value', snapshot => {
        const data = snapshot.val()
        // console.log("====data===", data)
        if (data) {
          setCart(data.cart)
          setCartView(true)
        }
      });
    // return () => Firebase.database().ref(`/ecommerce/orders/${storeUiniqId}/${urlOrderID}`).off('value', onChildAdd);
  }, [storeUiniqId && urlOrderID ])
  
  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/ecommerce/${storeUiniqId}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data && data.products) {
          let result = []
          const keys = Object.keys(data.products);
          for (let index = keys.length - 1; index > -1; index--) {
            if(data.products[keys[index]] && data.products[keys[index]].name && data.products[keys[index]].price && data.products[keys[index]].stock == 'yes'){
              const element = Object.assign(data.products[keys[index]], {id: keys[index]})
              result.push(element)
            }
          }
          setBaseList(result)
          setList(result)
        }
        if (data && data.people) {
          setPeople(data.people[Object.keys(data.people)[0]])
        }
        if (data && data.offers) {
          setCurrentOffer(data.offers[Object.keys(data.offers)[0]])
        }
        if (data && data.storelocation) {
          setStoreLocation(data.storelocation)
        }
        if ( data && data.branches) {
          let result = []
          const keys = Object.keys(data.branches);
          for (let index = keys.length - 1; index > -1; index--) {
            if(data.branches[keys[index]] && data.branches[keys[index]].name && data.branches[keys[index]].address){
              const element = Object.assign(data.branches[keys[index]], {id: keys[index]})
              result.push(element)
            }
          }
          if(result && result.length > 0 && !selectedBranch && !urlOrderID){
            setBranchSelectionModal(true)
          }
          setStoreBranches(result)
        }

        if (data && data.storeconfig) {
          setStoreConfig(data.storeconfig)
          if(data.storeconfig.menuonscan && data.storeconfig.menuimages){
            setMenuModal(true);
          }
        }
      });
    return () => Firebase.database().ref(`/ecommerce/${storeUiniqId}/`).off('value', onChildAdd);
  }, [storeUiniqId])

  // if(Instamojo){
  //   Instamojo.configure({
  //     handlers: {
  //       onOpen: function () {
  //         console.log('its open');
  //       },
  //       onClose: function (data) {
  //         console.log('its closed', data);
  //       },
  //       onSuccess: function (response) {
  //         console.log('success response', response);
  //       },
  //       onFailure: function (response) {
  //         console.log('failure response', response);
  //       },
  //     },
  //   });
  // }

  const getOrderLink = (orderId) => {
    return `http://table.onroad.app/${pathname}?orderid=${orderId}`
  }
  const increaseQty = (product) => {
    if(cart && cart[product.id]){
      const productCount = Number(cart[product.id].split("||")[3].split(":")[1]) + 1
      const productCost = productCount * Number(cart[product.id].split("||")[2].split(":")[1])
      setCart(prevCart => ({
        ...prevCart,
        [product.id]: `name:${product.name}||id:${product.id}||price:${product.price}||quantity:${productCount}||totalCost:${productCost}||productimage:${product.image}`
    }));
    } else{
      setCart(prevCart => ({
        ...prevCart,
        [product.id]: `name:${product.name}||id:${product.id}||price:${product.price}||quantity:1||totalCost:${product.price}||productimage:${product.image}`
      }));
    }
  }
  const decreaseQty = (product) =>{
    if(cart[product.id] && Number(cart[product.id].split("||")[3].split(":")[1]) > 0) {
      const productCount = Number(cart[product.id].split("||")[3].split(":")[1]) - 1
      if (productCount == 0){
        const { [product.id]: removedProperty, ...restObject } = cart;
        setCart(restObject);
      } else {
        const productCost = productCount *  Number(cart[product.id].split("||")[2].split(":")[1])
        // a[product.id] = `name:${product.name},id:${product.id},price:${product.price},quantity:${productCount},totalCost:${productCost}`
        // const t = Object.assign(cart, a)
        // setCart(t)
        setCart(prevCart => ({
          ...prevCart,
          [product.id]: `name:${product.name}||id:${product.id}||price:${product.price}||quantity:${productCount}||totalCost:${productCost}||productimage:${product.image}`
        }));
      }
    }
  }
  const getProductQuantity = (pid) => {
    if(cart[pid]){
      return cart[pid].quantity
    }
    return 0
  }
  const handleSearch = (event) => {
    setInput(event.target.value)
    if(event.target.value){
    setList(list.filter(x => x.name.toLowerCase().includes(event.target.value.toLowerCase())));
    }else{
      setList(baseList);
    }
  }
  const viewCart = () => {
    
    setCartView(true);
  }
  // const getCount = (id) => {

  //   return cart[id.toString()] ? cart[id.toString()].quantity : 0
  // }
  const previousOrdersList = () => {
    return (
      <>
      <Button
        className="btn-link"
        id="tooltip304204142"
        type="button"
        onClick={() => {
          setPreviousOrdersModal(true)
        }}
      >
        View Previous Orders            
      </Button>
      </>
    )
  }
  const productsPage = () => {
    
    return(
      <>
        <Modal
            isOpen={previousOrdersModal}
            className="text-center"
            toggle={togglePrevisouOrderModal}
          >
            <ModalHeader
              className="justify-content-center uppercase title"
              // toggle={toggleModalClassic}
              tag="h4"
              toggle={togglePrevisouOrderModal}
            >
              Previous Orders
            </ModalHeader>
            <ModalBody>
              {previousOrders?.map(order => {
                return (
                  <div style={{textAlign: "left"}}>
                    {Object.entries(order).map(([key,value]) => {
                    return (
                        <div>
                          <p style={{color: "gray"}}>Order: {key}</p>
                          <Table className="table-shopping" responsive>
                          <thead>
                            <tr>
                              <th className="text-center" />
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(value).map((entry) => {
                              const [key, value] = entry;
                              return(
                                <tr key={key}>
                                  
                                <td style={{width: "120px"}}>
                                  <div className="img-container">
                                    <img
                                      alt="..."
                                      src={value?.split("||productimage:")[1]}
                                    />
                                  </div>
                                </td>
                                <td className="td-name">
                                  <a href="#product" onClick={(e) => e.preventDefault()}>
                                    {value?.split("||")[0].split(":")[1]?.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() })}
                                  </a>
                                  <br />
                                  <small style={{fontSize: "1em"}}>Total: {value.split("||")[4].split(":")[1]}</small>
                                  <br />
                                  <small style={{fontSize: "1em"}}>Quantity: {value.split("||")[3].split(":")[1]}</small>
                                </td>      
                              </tr>
                              )
                            })}
                          </tbody>
                          </Table>
                        </div>
                    )})
                    }
                  </div>
                )
              })}
            </ModalBody>
            <br />
            <ModalFooter className="justify-content-center">
              <Button color="primary" className="justify-content-center" onClick={() => setPreviousOrdersModal(false)}>Ok</Button>
            </ModalFooter>
        </Modal>
       {tableNumber ? (
       <div className="table-shopping" responsive style={{textAlign: "center"}}>
              <h6 style={{margin: 0}}>
                Table {tableNumber}&nbsp;
              </h6>
              {previousOrders && previousOrders.length > 0 && previousOrdersList()}
              <br />
          </div>
          ): ""}
      <CardTitle style={{display: "flex"}}>
        <div style={{width: "90%", textAlign: "left", paddingTop: "10px", paddingRight: "20px"}}>
          <InputGroup className="no-border" style={{height: "60px"}}>
            <Input placeholder="Search..." value={input} onChange={handleSearch}/>
          </InputGroup>
        </div>
        <div style={{textAlign: "right"}}>
          <Button
            className="btn-round"
            color="info"
            id="tooltip304204142"
            type="button"
            onClick={viewCart}
            disabled={(!cart || Object.entries(cart).length == 0)}
            style={{paddingLeft: 10, paddingRight: 10, height: "60px"}}
          >
            <i className="now-ui-icons shopping_cart-simple" style={{paddingRight: "3px"}}/>
            Cart {cart && getTotalProductsCount()}
          </Button>
        </div>
      </CardTitle>
      </>
    )
  }
  const getTotalProductsCount = () => {
    if(!cart) {
      return
    }
    let count = 0
    Object.entries(cart).forEach(entry => {
      const [key, value] = entry;
      count = count + Number(value.split("||")[3].split(":")[1])
      })
    return count
  }
  const getTotalCost = () => {
    if(!cart){
      return 
    }
    let cost = 0
    Object.entries(cart).forEach(entry => {
      const [key, value] = entry;
      cost = cost + Number(value.split("||")[4].split(":")[1])
      })
    return cost
  }

  const placeTheOrder = async () => {
    if(tableNumber == 'generic' && !name){
      setIsError(true)
      return ;
    }
    setIsError(false)
    setShowSuccessMessage(true);
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss")
    setOrderPlacedTime(currentTime)
    const orderRef = await Firebase.database()
      .ref(`/tableorders/${storeUiniqId}/`).push({
        cart: cart,
        table: tableNumber,
        time: currentTime,
        name: name ? name : ""
      })
    const orderFBID = await orderRef.key;
    setOrderId(orderFBID);
    let localCart = {}
    localCart[currentTime] = cart
    const t = await window.localStorage.getItem("onroadcart")
    if(t){
      let a = JSON.parse(t)
      if(a && a.length > 0){
        setPreviousOrders([localCart, ...a])
        window.localStorage.setItem('onroadcart', JSON.stringify([localCart, ...a]))
      }else{
        setPreviousOrders([localCart])
        window.localStorage.setItem('onroadcart', JSON.stringify([localCart]))
      }
    }else{
      setPreviousOrders([localCart])
      window.localStorage.setItem('onroadcart', JSON.stringify([localCart]))
    }
    const { data } = await gf.random({ tag: 'waiting for food' })
    setGiphyEmbedURL(data.embed_url)
  }

  const handleStoreSelection = (branch) => {
    setSelectedBranch(branch)
  }

  const closeTheBranchModal = () => {
    if(selectedBranch) {
      setBranchSelectionModal(false)
    }
  }

  const orderCompleteSuccess = () => {
    if(!orderId){
      return (
        <Spinner animation="border" role="status">
          Loading...
        </Spinner>
      )
    }
    // const { data } = await gf.search('waiting for food')
    // console.log("====data====", data)
    return (
      <>
        <h3>
          Food is on the way
        </h3>
        <iframe src={giphyEmbedURL} frameBorder="0" class="giphy-embed" allowFullScreen></iframe>

      </>
    )
  }

  const orderInputs =  () => {
    // const { data } = await gf.search('waiting for food')
    // console.log("====data====", data)
    return (
      <>
      <Row style={{width: "100%", paddingLeft: "10%"}}>
        <Label>Total Cost:
          <span className="info" style={{color: "#555555", paddingLeft: "60px", fontWeight: "bolder"}}>
            {getTotalCost()}
          </span>
        </Label>
      </Row>
      <Row style={{width: "100%", paddingLeft: "10%"}}>
        <Label>Total Items:
          <span className="info" style={{color: "#555555", paddingLeft: "52px", fontWeight: "bolder"}}>
            {getTotalProductsCount()}
          </span>
        </Label>
      </Row>
      <Row style={{width: "100%", paddingLeft: "10%"}}>
        <Label>Time Estimate: 
          <span className="info" style={{color: "#555555", paddingLeft: "30px", fontWeight: "bolder"}}>
            5 to 20 Mins
          </span>
        </Label>
      </Row>
      {tableNumber === 'generic' && (<Row style={{width: "100%", paddingLeft: "10%"}}>
        <Label sm="3">Name For The Order</Label>
        <Col sm="9" style={{textAlign: "left"}}>
          <FormGroup>
            <Input defaultValue="" placeholder="Full name" type="text" required onBlur={(e)=>{
              setName(e.target.value)
            }}/>
          </FormGroup>
        </Col>
      </Row>)}
      <br />
      {/* <b>Payment Accepted Via: {storeLocation && (storeLocation.paymentmethod || "Not setup Yet")},  Phone Number: {people && people.phone}</b> */}
      </>
    )
  }

  const cartPage = () => {
    return(
      <CardTitle style={{display: "flex"}}>
        <div style={{width: "10%", textAlign: "left", paddingTop: "10px", paddingRight: "20px"}}>
          {urlOrderID ? "" : (<Button
              className="btn-link"
              color="info"
              id="tooltip304204142"
              type="button"
              onClick={(e)=>{setCartView(false)}}
              style={{paddingLeft: 10, paddingRight: 10, height: "60px"}}
            >
              <i className="now-ui-icons arrows-1_minimal-left" style={{paddingRight: "3px", fontSize: "3em"}}/>
          </Button>)}
        </div>
        <div style={{width: "80%", textAlign: "center", paddingTop: "10px", paddingRight: "20px"}}>
          <h3 className="info" style={{color: "#555555"}}>Items: {getTotalProductsCount()}<br/>Total: {getTotalCost()}</h3>
        </div>
        <Modal
            isOpen={modalClassic}
            className="text-center"
            toggle={toggleModalClassic}
          >
            <ModalHeader
              className="justify-content-center uppercase title"
              // toggle={toggleModalClassic}
              tag="h4"
              toggle={toggleModalClassic}
            >
              {orderId ? "Awesome": "Complete The Order"}
              <br />
              {isError ? (<span style={{color: "red", fontSize: "10px"}}>Please enter name for the order</span>) : ""}
            </ModalHeader>
            <ModalBody>
              {showSuccessMessage ? orderCompleteSuccess() : orderInputs()}
            </ModalBody>
            <br />
            {orderId ? (
              <ModalFooter className="justify-content-center">
                <Button color="primary" className="justify-content-center" onClick={() => {
                  setCart(null)
                  setCartView(false)
                  setModalClassic(!modalClassic);
                }}>Ok, I want more</Button>
              </ModalFooter>
            ) : (
            <ModalFooter className="justify-content-center">
              <Button color="primary" className="justify-content-center" onClick={() => placeTheOrder()}>Place The Order</Button>
            </ModalFooter>
            )}
        </Modal>
        <div style={{textAlign: "right"}}>
        {/* <a href="https://www.instamojo.com/@onroadlivetracking/l8724040d0c794a36baf02f5ac52b0262/" rel="im-checkout" data-text="Complete Payment" data-css-style="color:#ffffff; background:#db3e00; width:180px; border-radius:4px"   data-layout="vertical"></a> */}

        {urlOrderID ? "" : (<Button
            className="btn-round"
            color="danger"
            id="tooltip304204142"
            type="button"
            // onClick={handlePayment}
            onClick={toggleModalClassic}
            style={{height: "60px", fontSize: "1em"}}
          >
            Proceed Checkout
          </Button>) }
          

        </div>
      </CardTitle>
    )
  }
  const mainProductList = () => {
    return (
    <Table className="table-shopping" responsive>
      <thead>
        <tr>
          <th className="text-center" />
          <th>Product</th>
          {/* <th className="text-right">Price</th> */}
          {/* <th className="text-right">Qty</th> */}
        </tr>
      </thead>
      <tbody>
        {list && list.map((product, key) => {
          return(
            <tr key={key}>
              <td style={{width: "120px", padding: "2px"}}>
                <div className="img-container">
                  <img
                    alt="..."
                    src={product.image}
                  />
                </div>
              </td>
              <td className="td-name" style={{fontSize: "1em", padding: "2px"}}>
                <a href="#product" onClick={(e) => e.preventDefault()}>
                  {product?.name?.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() })}&nbsp;
                </a>
                &nbsp;&nbsp;&nbsp;
                {product.discount && product.discount > 60 ? (<span style=
                {{color: "white",background: "#9c3f3f", fontSize: "8px", fontWeight: "200",
                  padding: "5px", marginTop: "10px", verticalAlign: "middle",
                  borderRadius: "10px"}}>Hot Deal</span>): null}
                <br />
                <small style={{fontSize: "1em"}}>{product.discount>0 ? (<span style={{textDecoration: "line-through"}}>{parseInt((100/(100 - product.discount)) * product.price)}</span>): ""}&nbsp;&nbsp;{product.price}</small>
                <br />
                {product.discount && product.discount > 0 && (<small style={{fontSize: "1em"}}>{product.discount || 0}% Discount </small>)}
                {product.description && (<> <br /><small style={{fontSize: "12px", color: "black"}}>{product.description || ""} </small></>)}

                <br />
                {/* {innerWidth < 700 ? incDecButtons() : null} */}
                 <div>
                 &nbsp;
                  <ButtonGroup>
                    <Button color="#9a9a9a" size="sm" onClick={() => decreaseQty(product)} style={{paddingLeft: "5px",paddingTop: "2px", height: "20px", width: "30px", top: "0px", bottom: "3px"}}>
                      <i className="now-ui-icons ui-1_simple-delete" />
                    </Button>
                    <span style={{background: "#d3d2d2", height: "20px"}}>
                    &nbsp;
                    &nbsp;
                     {(cart && cart[product.id]) ? `${cart[product.id].split("||")[3].split(":")[1]} ` : 0} 
                    &nbsp;
                    &nbsp;
                    </span>
                    <Button color="#9a9a9a" size="sm" onClick={() => increaseQty(product)} style={{paddingLeft: "5px",paddingTop: "2px", height: "20px", width: "30px", top: "0px", bottom: "3px"}}>
                      <i className="now-ui-icons ui-1_simple-add" />
                    </Button>
                  </ButtonGroup>
                  
                  
                </div>
              </td>
          </tr>
          )
        })}
      </tbody>
    </Table>
  )
  }
  const cartProductList = () => {
    return (
      <Table className="table-shopping" responsive>
      <thead>
        <tr>
          <th className="text-center" />
          {/* <th>Cart</th> */}
          {/* <th className="text-right">Price</th>
          <th className="text-right">Qty</th> */}
        </tr>
      </thead>
      <tbody>
        {Object.entries(cart).map((entry) => {
          const [key, value] = entry;
          return(
            <tr key={key}>
              
            <td style={{width: "120px"}}>
              <div className="img-container">
                <img
                  alt="..."
                  src={value.split("||productimage:")[1]}
                />
              </div>
            </td>
            <td className="td-name">
              <a href="#product" onClick={(e) => e.preventDefault()}>
                {value?.split("||")[0].split(":")[1]?.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() })}
              </a>
              <br />
              <small style={{fontSize: "1em"}}>Total: {value.split("||")[4].split(":")[1]}</small>
              <br />
              <small style={{fontSize: "1em"}}>Quantity: {value.split("||")[3].split(":")[1]}</small>
            </td>      
          </tr>
          )
        })}
      </tbody>
      </Table>
    )
  }
  return (
    <>
      <div className="panel-header panel-header-sm" >
        <h1 style={{
          marginTop: "-50px",
          color: "white",
          textAlign: "center"}}> {storeName}
        </h1>
        {people && people.length > 0 ? (
          <p style={{
            textAlign: "center",
            marginTop: "-37px",
            marginRight: "40px",
            color: "white"
            }}>Ph: {people[0].phone}</p>
        ) : ""}
      </div>
      <Modal
        isOpen={menuModal && !cartView}
        className="text-center"
        toggle={toggleMenuModal}
      >
        <ModalHeader
          className="justify-content-center uppercase title"
          // toggle={toggleModalClassic}
          tag="h4"
          toggle={toggleMenuModal}
        >
          Store Menu
          <br />
        </ModalHeader>
        <ModalBody>
        <div className="img-container">
          {storeConfig && storeConfig.menuimages && Object.values(storeConfig.menuimages).map((imageURL) => <img
            alt="..."
            src={imageURL}
            style={{padding: "5px"}}
          />)}
        </div>
        </ModalBody>
        <br />
        
        <ModalFooter className="justify-content-center">
          <Button color="primary" className="justify-content-center" onClick={() => setMenuModal(false)}>Ok</Button>
        </ModalFooter>
      </Modal>
    
      <div className="content">
        {currentOffer && currentOffer.name && currentOffer.description && (<Row className="justify-content-center">
          <Col lg="8" sm="12">
            <Card className="card-stats">
              <CardBody>
                <div className="statistics statistics-horizontal">
                  <div className="info info-horizontal">
                    <Row>
                      {width > 700 && (<Col lg="2" sm="1" md="3" xs="2" className="visible-md-block d-md-block">
                        <div className="icon icon-danger icon-circle">
                          <i className="now-ui-icons sport_user-run" />
                        </div>
                      </Col>)}
                      <Col lg={width > 700 ? "8" : "12"} sm={width > 700 ? "8" : "12"} md={width > 700 ? "8" : "12"}>
                        <h3 className="info-title" style={{textAlign: "center"}}>{currentOffer.name}</h3>
                        <p style={{textAlign: "center"}}>{currentOffer.description}</p>
                      </Col>
                      {width > 700 && (<Col className="text-right visible-md-block d-sm-block d-md-block" lg="2" sm="2" md="2" xs="2" >
                        <h3 className="info-title">50+</h3>
                        <h6 className="stats-title">Calimed</h6>
                      </Col>)}
                    </Row>
                  </div>
                </div>
              </CardBody>
              <hr />
              <CardFooter>
                <div className="stats">
                  <i className="now-ui-icons ui-2_time-alarm" />
                  Offer will expire on {currentOffer.expirydate}
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>)}
        {/* {previousOrders && previousOrders.length > 0 (<Row className="justify-content-center">
          <Col lg="8" sm="12">
            {previousOrders.map((k, v) => (<Card className="card-stats">
              <CardBody>
                {k}{v}
              </CardBody>
              <hr />
              <CardFooter>
                <a href="">View Order</a>
              </CardFooter>
            </Card>))}
          </Col>
        </Row>)} */}
        <Row className="justify-content-center">
          <Col md="8">
            <Card>
              <CardHeader>
                {cartView ? cartPage() : productsPage()}
              </CardHeader>
              <CardBody>
               {cartView ? cartProductList() : mainProductList()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      </>
  );
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default ExtendedTables;
